<template>
  <base-view
      @update:search-values="updateSearch"
      :search-columns="searchColumns">

    <detalhe-matricula-dialog :item="item" v-model="editDialogOpened" />
    <div>
      <minhas-matriculas-data-table ref="dataTable"
                             @click:viewDetalhe="verDetalhe"
                             :searchValues="searchValues"/>
    </div>
  </base-view>

</template>

<script>
import MinhasMatriculasDataTable from "../../components/shared/datatables/MinhasMatriculasDataTable";
import BaseView from "../BaseView";
import {findAllClienteLogadoMaisSeusDependentes as findAllCliente} from '../../api/clientes';
import DetalheMatriculaDialog from "./components/DetalheMatriculaDialog";

export default {
  name: "index",
  components: {BaseView, MinhasMatriculasDataTable, DetalheMatriculaDialog},
  data() {
    return {
      item: null,
      editDialogOpened: false,
      searchValues: {},
      searchColumns: [
        {
          name: 'Cliente',
          column: 'idCliente',
          type: 'autocomplete',
          optionsFactory: (search) => findAllCliente(1,{
            nome: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.nome
                }))
              })

        },
      ]
    }
  },
  methods: {
    updateSearch(search) {
      this.searchValues = search;
    },
    verDetalhe(aula) {
      this.item = JSON.parse(JSON.stringify(aula));
      this.editDialogOpened = true;
    },
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">

::v-deep {
  .v-data-table__wrapper {
    height: calc(100vh - 157px);
  }
}

</style>