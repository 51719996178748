var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('responsive-menu',{ref:"menu",attrs:{"title":_vm.selectedItemName,"opcoes":_vm.menuOptions},on:{"click:item":function (action) { return _vm.$emit(("click:" + action), _vm.selectedItem); }}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"dense":"","fixed-header":"","options":_vm.pageOptions,"server-items-length":_vm.totalElements,"disable-sort":true,"hide-default-header":_vm.$vuetify.breakpoint.mobile,"footer-props":{
    disableItemsPerPage: true,
    itemsPerPageOptions: [_vm.itemsPerPage],
  },"items":_vm.elements,"loading":_vm.loading},on:{"update:options":function($event){_vm.pageOptions=$event},"click:row":_vm.onClickRow,"contextmenu:row":function($event){$event.preventDefault();return _vm.showMenu.apply(null, arguments)}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
  var item = ref.item;
return [_c('strong',[_vm._v("#"+_vm._s(item.id))])]}},{key:"item.tipoAluno",fn:function(ref){
  var item = ref.item;
return [_c('span',{class:_vm.corTipoAluno(item.tipoAluno)},[_vm._v(_vm._s(item.tipoAluno))])]}},{key:"item.dtcMatricula",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.dtcMatricula,'DD/MM/YYYY'))+" ")]}},{key:"item.edit",fn:function(ref){
  var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){$event.preventDefault();return _vm.showMenu($event, item)}}},[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}},(_vm.$vuetify.breakpoint.mobile)?{key:"body",fn:function(ref){
  var items = ref.items;
  var isSelected = ref.isSelected;
  var select = ref.select;
return [_c('v-list',{staticClass:"pa-0",attrs:{"dense":""}},_vm._l((items),function(item){return _c('v-list-item',{key:item.id,staticClass:"list-item",on:{"click":function($event){return _vm.showMenu($event, item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.nomeIdCliente)+" "),_c('span',{class:_vm.corTipoAluno(item.tipoAluno)},[_vm._v("("+_vm._s(item.tipoAluno)+")")])])],1),_c('v-list-item-action-text',[_c('v-chip',{staticClass:"mr-2",attrs:{"color":"primary","input-value":"false","small":""}},[_vm._v("R$ "+_vm._s(item.valComDesconto))])],1),_c('v-list-item-action-text',[_c('v-chip',{attrs:{"small":"","input-value":"false","outlined":""}},[_vm._v(_vm._s(item.stsMatricula === 'A' ? 'Ativo' : 'Inativo')+" ")])],1),_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)],1)}),1)]}}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }