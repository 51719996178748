<template>
<v-dialog :fullscreen="$vuetify.breakpoint.mobile"
          :value="value"
          max-width="800px"
          persistent>
  <v-card class="d-flex flex-column">
    <v-card-title>
      {{getTitle}}
      <v-spacer/>
      <v-btn icon @click="$emit('input', false)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider/>
    <v-card-text class="py-4 flex-grow-1" v-if="value">
    <!--dados da matricula-->
    <v-col cols="12" class="">
        <v-row>
            <v-col cols="12" md="12">
                <b>Local</b>: {{item.descricaoIdLocal}}
            </v-col>
        </v-row>
    </v-col>   
    
    </v-card-text>
    <v-divider/>
    <v-card-actions>
      <v-spacer/>
      <v-btn outlined color="primary" @click="$emit('input', false)">fechar</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>

export default {
  name: "DetalheMatriculaDialog",
  props: ['value','item'],
  data () {
    return {
    }
  },
  computed: {
    getTitle() {
      return 'Detalhe matrícula:  ' + this.item?.nomeIdCliente
    },
  },

}
</script>

<style scoped>

</style>